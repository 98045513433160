$offset: 12px;

.navigation-menu-outer {
	height: 100%;
}
.navigation {
	&__menu {
		margin: 0;
		height: 100%;
		display: flex;
		&:first-child {

			
			padding-left: 0;
		}
		&:last-child {
			padding-right: 0;
		}
	}
	&__item {
		list-style-type: none;
		display: inline-block;
		height: 100%;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 0;
	}
	&__link {
		&:link, &:visited {
			padding: 2px 22px;
			text-decoration: none;
			transition: all 0.2s ease;
			color: $black;
			background-color: $white;
			height: 100%;
			display: flex;
			align-items: center;
		}
		&.desktop-screen {
			&:hover, &:active {
				color: $theme-highlight;
			}
		}
		&.touch-screen {
			&:active {
				color: $theme-highlight;
			}
		}	
	}
	&--desktop {
		.navigation {
			&__submenu {
				float: left;
				left: 0;
				position: absolute;
				top: auto;
				bottom: 0;
				transform: translateY(100%);
				z-index: 1;
				width: 180px;
				.navigation__item {
					overflow: hidden;
					transition: all 0.3s ease;
					width: 100%;
					height: auto;
					max-height: 0;
					justify-content: flex-start;
					width: 100%;
				}
				.navigation__link {
					padding: 15px 22px;
					display: block;
					width: 100%;
				}
			}
			&__item {
				&.menu-item-has-children:hover {
					.navigation__item {
						max-height: 100px;
					}
				}
			}
		}
	}

}


.navigation--social .navigation {
	&__item {
		padding: 2px 10px;
		
		&.facebook .navigation__link {
			// @include iconBackground("./assets/img/social/facebook-dark-blue.png", 22px, 22px );
		}
		&.instagram .navigation__link {
			// @include iconBackground("./assets/img/social/instagram-dark-blue.png", 22px, 22px );
		}
		&.twitter .navigation__link {
			// @include iconBackground("./assets/img/social/twitter-dark-blue.png", 22px, 22px );
		}
		&.pinterest .navigation__link {
			// @include iconBackground("./assets/img/social/twitter-dark-blue.png", 22px, 22px );
		}
	}
	
}









