@charset "UTF-8";
/*!
Theme Name: BJZM
Author: Zafer Sawaf and Matt McCollough
Author URI: www.blackjet.ca
Description: Blackjet's advanced starter Wordpress theme. 
Version: 1.0.0
License: GNU General Public License v2 or later
License URI: http://www.gnu.org/licenses/gpl-2.0.html
Text Domain: bjzm
*/
/* Import Font Awesome */
@import https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css;
/* ----------------------------------------------------------------------------
 * Vendors
 * ------------------------------------------------------------------------- */
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0; }

.slick-list:focus {
  outline: none; }

.slick-list.dragging {
  cursor: pointer;
  cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block; }

.slick-track:before,
.slick-track:after {
  display: table;
  content: ''; }

.slick-track:after {
  clear: both; }

.slick-loading .slick-track {
  visibility: hidden; }

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px; }

[dir='rtl'] .slick-slide {
  float: right; }

.slick-slide img {
  display: block; }

.slick-slide.slick-loading img {
  display: none; }

.slick-slide.dragging img {
  pointer-events: none; }

.slick-initialized .slick-slide {
  display: block; }

.slick-loading .slick-slide {
  visibility: hidden; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* ----------------------------------------------------------------------------
 * Mixins & Variables
 * ------------------------------------------------------------------------- */
.row {
  display: flex; }

.col-1 {
  width: 8.33333%;
  flex-basis: 8.33333%; }

.col-2 {
  width: 16.66667%;
  flex-basis: 16.66667%; }

.col-3 {
  width: 25%;
  flex-basis: 25%; }

.col-4 {
  width: 33.33333%;
  flex-basis: 33.33333%; }

.col-5 {
  width: 41.66667%;
  flex-basis: 41.66667%; }

.col-6 {
  width: 50%;
  flex-basis: 50%; }

.col-7 {
  width: 50%;
  flex-basis: 50%; }

.col-8 {
  width: 66.66667%;
  flex-basis: 66.66667%; }

.col-9 {
  width: 75%;
  flex-basis: 75%; }

.col-10 {
  width: 83.33333%;
  flex-basis: 83.33333%; }

.col-11 {
  width: 91.66667%;
  flex-basis: 91.66667%; }

/* @include largeTabletMax {
	div[class^="col-"] {
		width: 100%;
	}
} */
/**
 * Colours
 */
/* header heights */
/*
* MEDIA QUERIES
 */
/**
 * Container widths
 */
/**
 * Colours
 */
/* https://codepen.io/flodar/pen/erism */
/*
* @horizontalcenter 
* Horizontally align an element.
*/
/*
* @center
* Horizontally and vertically center an element
*/
/*
* @absolutesize
* Make element position absolute, width 100% and height 100%
*/
/* -------------------------------------------------------------------------
*
* 2- Background Image
*
* - Background Image: position center and size cover
* - Background Image Contain: position center and size contain
* - Background Image Custom: use this only if you want custom settings 
* 
* ------------------------------------------------------------------------- */
/**
* @bgimage
* Given a url, put in a bg image with position center and size cover
*/
/**
* @bgimagecontain
* Given a url, put in a bg image with position center and size contain
*/
/**
* @bgimagecustom
*/
/* -------------------------------------------------------------------------
*
* 3- Floats and clearing
*
* - Clear: Apply this to parent to clear floats.
*
* ------------------------------------------------------------------------- */
/*
* Mixin for clearfix
* @include clearfix;
*/
/* -------------------------------------------------------------------------
*
* 4- Fonts
*
* - Font-face family usage examples
*      Create a font face rule that applies to 500 weight text and sources EOT, WOFF2, and WOFF.
*      @include font-face(Samplinal, fonts/Samplinal, 500, normal, eot woff2 woff);
*
*      Create a font face rule that applies to bold and italic text.
*      @include font-face("Samplina Neue", fonts/SamplinaNeue, bold, italic);
*
* ------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------
*
* 5- Images
*
* 
* ------------------------------------------------------------------------- */
@keyframes buttonSwell {
  0% {
    transform: scale(1); }
  50% {
    transform: scale(1.03); }
  100% {
    transform: scale(1); } }

/**
 * Container
 */
/**
 * Page Wrapper
*/
/**
 * Full Height
 */
/**
 * column widths
*/
/* get fixed width given column width percentage, container fixed width */
/* include responsive width styles given column width */
/**
 * Heading Sizes
 */
/* Custom Selection Colour */
*::selection {
  background: #028223;
  color: white !important; }

/* ----------------------------------------------------------------------------
 * 3rd Party
 * ------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------
 * Common & Structure
 * ------------------------------------------------------------------------- */
.page-outer {
  width: 100%; }

.page-wrapper {
  width: 100%;
  margin-right: auto;
  margin-left: auto; }

.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 20px;
  padding-right: 20px; }
  @media (min-width: 481px) {
    .container {
      width: 100%;
      padding-left: 30px;
      padding-right: 30px; } }
  @media (min-width: 768px) {
    .container {
      width: 700px;
      padding-left: 0;
      padding-right: 0; } }
  @media (min-width: 992px) {
    .container {
      width: 940px;
      padding-left: 0;
      padding-right: 0; } }
  @media (min-width: 1321px) {
    .container {
      width: 1150px;
      padding-left: 0;
      padding-right: 0; } }
  .container__relative {
    position: relative; }

body.development .page-outer {
  background-color: rgba(135, 211, 124, 0.58); }

body.development .page-wrapper {
  background-color: rgba(34, 167, 240, 0.5); }

body.development .container {
  background-color: rgba(137, 114, 158, 0.46); }

/* ----------------------------------------------------------------------------
 * Structure
 * ------------------------------------------------------------------------- */
/* global box-sizing */
*,
*:after,
*:before {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility; }

/* html element 62.5% font-size for REM use */
html {
  height: 100%;
  min-height: 100%; }

body {
  font-size: 16px;
  margin: 0;
  height: 100%;
  min-height: 100%; }

html, body {
  margin: 0 !important; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.bjzm-wrapper {
  padding-top: 130px;
  padding-bottom: 30px;
  overflow: hidden; }
  .bjzm-wrapper--cards .bjzm-wrapper__inner, .bjzm-wrapper--sections .bjzm-wrapper__inner {
    display: flex;
    padding-left: 15px;
    padding-right: 15px; }

/* clear */
.clear:before,
.clear:after {
  content: ' ';
  display: table; }

.clear:after {
  clear: both; }

.clear {
  *zoom: 1; }

img {
  max-width: 100%;
  height: auto; }

li {
  list-style: none; }

input:focus {
  outline: 0; }

@media (max-width: 920px) {
  .bjzm-wrapper {
    padding-top: 65px; } }

html, body {
  font-family: "Comic Sans", "Comic Sans MS", "Chalkboard", "ChalkboardSE-Regular", sans-serif; }

a {
  transition: all 0.3s;
  font-size: inherit; }
  a:link, a:visited, a:active, a:focus {
    outline: 0;
    cursor: url("../assets/img/tree.png"), auto; }
  a:hover {
    text-decoration: none; }

button:hover, button:active {
  cursor: url("../assets/img/tree.png"), auto; }

p, li {
  font-family: "Comic Sans", "Comic Sans MS", "Chalkboard", "ChalkboardSE-Regular", sans-serif; }
  @media (min-width: 992px) {
    p, li {
      font-size: 18px;
      font-weight: 400;
      line-height: 30px; } }
  @media (max-width: 991px) {
    p, li {
      font-size: 16px;
      line-height: 24px; } }
  @media (max-width: 480px) {
    p, li {
      font-size: 14px;
      line-height: 19px; } }
  p.jab, li.jab {
    color: gold;
    font-size: 28px;
    font-style: italic; }

a {
  display: inline; }

.h1-wrapper {
  position: relative; }

h1 {
  font-size: 70px;
  font-weight: 700;
  line-height: 70px;
  text-align: center;
  display: inline-block;
  text-shadow: none;
  margin: 0 auto;
  font-weight: bold;
  margin-bottom: 120px;
  line-height: 70px;
  text-align: center;
  margin: 20px auto 40px;
  text-shadow: 5px 5px 5px white;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #E3D100;
  color: #028223;
  font-family: "Comic Sans", "Comic Sans MS", "Chalkboard", "ChalkboardSE-Regular", sans-serif; }
  @media (max-width: 991px) {
    h1 {
      font-size: 60px;
      line-height: 1; } }
  @media (max-width: 767px) {
    h1 {
      font-size: 30px !important;
      line-height: 1 !important;
      margin: 0;
      text-indent: 0;
      text-shadow: 3px 3px 1px white; } }

h2 {
  font-size: 40px;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  font-family: "Comic Sans MS", cursive, sans-serif;
  text-shadow: 3px 3px 0 #fe9b49;
  font-family: "Comic Sans", "Comic Sans MS", "Chalkboard", "ChalkboardSE-Regular", sans-serif; }
  @media (max-width: 991px) {
    h2 {
      font-size: 20px;
      line-height: 1; } }
  @media (max-width: 767px) {
    h2 {
      font-size: 17px;
      text-shadow: 2px 2px 0 #fe9b49;
      line-height: 1; } }

h3 {
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  font-family: "Comic Sans", "Comic Sans MS", "Chalkboard", "ChalkboardSE-Regular", sans-serif; }
  @media (max-width: 991px) {
    h3 {
      font-size: 15px;
      line-height: 1; } }
  @media (max-width: 767px) {
    h3 {
      font-size: 13px;
      line-height: 1; } }

h4 {
  font-size: 18px;
  font-family: "Comic Sans", "Comic Sans MS", "Chalkboard", "ChalkboardSE-Regular", sans-serif; }

h5 {
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  font-family: "Comic Sans", "Comic Sans MS", "Chalkboard", "ChalkboardSE-Regular", sans-serif; }

a:link, a:visited {
  color: inherit;
  text-decoration: none; }

a.desktop-screen:hover, a.desktop-screen:active {
  -webkit-tap-highlight-color: transparent;
  text-decoration: none; }

a.image-link {
  border-bottom: none; }

.custom-counter {
  counter-reset: section; }

ul:not(.bjzm-gallery__list):not(.bjzm-slideshow__list),
ol {
  margin-left: 10px;
  margin-bottom: 40px; }

li {
  list-style-type: none;
  margin-bottom: 20px; }
  li::before {
    content: "·";
    font-size: 28px;
    left: -10px;
    position: relative; }

ol {
  list-style: none;
  counter-reset: li;
  max-width: 700px;
  margin: 0 auto;
  max-width: 700px;
  margin: 0 auto 50px auto; }

ol li::before {
  content: counter(li);
  color: #E3D100;
  display: inline-block;
  width: 1em;
  margin-left: -1em; }

li {
  counter-increment: li; }

/* ----------------------------------------------------------------------------
 * Components
 * ------------------------------------------------------------------------- */
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  background-color: #FC101D;
  color: #FFFFFF; }

.navbar {
  position: absolute !important;
  width: 100%;
  background-color: transparent;
  display: none; }

#root {
  height: 100%; }

.page-wrapper {
  min-height: 100%; }
  .page-wrapper .container {
    height: 100%; }
  .page-wrapper__inner {
    display: flex;
    align-items: center;
    min-height: 100%;
    min-height: 100vh; }

.credit a:link, .credit a:visited {
  color: #E3D100; }

.credit a:hover, .credit a:active {
  color: #028223; }

.main--welcome {
  padding-top: 90px; }
  .main--welcome .site-loader-outer {
    animation: fadeOut 1s;
    animation-fill-mode: forwards;
    animation-delay: 6s;
    z-index: 5; }
    .main--welcome .site-loader-outer .credit {
      position: absolute;
      font-size: 13px;
      bottom: 0;
      width: 100%;
      color: red;
      bottom: 90px;
      text-align: right;
      right: 80px; }
      .main--welcome .site-loader-outer .credit a:link, .main--welcome .site-loader-outer .credit a:visited {
        color: #E3D100; }
      .main--welcome .site-loader-outer .credit a:hover, .main--welcome .site-loader-outer .credit a:active {
        color: #028223; }
  .main--welcome .site-main-intro {
    opacity: 0;
    position: relative;
    z-index: 10;
    animation: fadeIn 1s;
    animation-fill-mode: forwards;
    animation-delay: 6s; }

@media (max-width: 767px) {
  .main--instructions,
  .main--welcome,
  .main--setup {
    padding-top: 0;
    min-height: 100vh; }
    .main--instructions .hero,
    .main--welcome .hero,
    .main--setup .hero {
      padding-top: 150px; }
  .main--setup .hero {
    padding-top: 80px; }
  .main--winner {
    min-height: 100vh; } }

/**
* Setup section
*/
.setup-section {
  padding: 20px 0;
  min-height: calc(100vh);
  display: flex;
  flex-direction: column;
  justify-content: center; }
  .setup-section--counter {
    min-height: initial; }

.main {
  padding-bottom: 30px;
  width: 100%; }
  .main--setup {
    background-image: url("../assets/img/santa-one.jpeg");
    background-size: 400%;
    background-position: center;
    background-attachment: fixed;
    padding: 60px 0;
    min-height: 100vh; }
  .main--is-winner-loading {
    background: repeating-radial-gradient(circle at top left, cyan, yellow 2em, magenta 4em, cyan 6em), repeating-radial-gradient(circle at bottom left, cyan, yellow 2em, magenta 4em, cyan 6em), repeating-radial-gradient(circle at top right, cyan, yellow 2em, magenta 4em, cyan 6em), repeating-radial-gradient(circle at bottom right, cyan, yellow 2em, magenta 4em, cyan 6em);
    background-blend-mode: multiply;
    height: 100vh; }
    .main--is-winner-loading h2 {
      margin-top: 20px; }
  .main--is-winner-announced {
    background-image: repeating-linear-gradient(90deg, firebrick, firebrick 20px, beige 0, beige 25px, forestgreen 0, forestgreen 35px, gold 0, gold 40px, crimson 0, crimson 60px), repeating-linear-gradient(firebrick, firebrick 20px, beige 0, beige 25px, forestgreen 0, forestgreen 35px, gold 0, gold 40px, crimson 0, crimson 60px);
    background-blend-mode: multiply; }

input[type="text"],
input[type="number"] {
  margin: 10px 0; }

.get-ready img {
  width: auto;
  height: calc(100vh - 230px);
  margin: 20px auto; }
  @media (max-width: 767px) {
    .get-ready img {
      height: calc(72vh - 230px); } }

.get-ready,
.winner-content {
  text-align: center;
  width: 100%; }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(15px); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

/**
* Review page
*/
.setup-section--review p {
  opacity: 0;
  transform: translateY(15px);
  text-shadow: 1px 1px 1px #FC101D; }
  .setup-section--review p.players {
    transform: none;
    opacity: 1;
    margin-top: 30px; }
  .setup-section--review p.bonus {
    opacity: 0;
    animation: fadeInUp 0.3s linear 2s;
    animation-fill-mode: forwards; }
  .setup-section--review p.music {
    opacity: 0;
    animation: fadeInUp 0.3s linear 3.5s;
    animation-fill-mode: forwards; }
  .setup-section--review p.jab {
    opacity: 0;
    animation: fadeInUp 0.3s linear 5s;
    animation-fill-mode: forwards; }

.setup-section--review label {
  opacity: 0;
  animation: fadeInUp 0.3s linear 5s;
  animation-fill-mode: forwards; }

.setup-section--review .cta-row {
  opacity: 0;
  animation: fadeInUp 0.3s linear 6.5s;
  animation-fill-mode: forwards; }

.winner-loading img {
  height: calc(100vh - 130px);
  margin-top: 20px; }
  @media (max-width: 767px) {
    .winner-loading img {
      height: calc(72vh - 230px); } }

.winner-columns {
  display: flex;
  height: 100%; }
  .winner-columns .h1-wrapper h1 {
    font-size: 100px; }
  .winner-columns .winner-left {
    width: 50%;
    min-height: 30px;
    display: flex;
    min-height: 100vh; }
  .winner-columns .winner-column-inner {
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    min-height: 100vh;
    display: flex; }
  .winner-columns .winner-gif {
    width: 50%;
    opacity: 0;
    animation: fadeIn 0.2s linear 1.5s;
    animation-fill-mode: forwards;
    min-height: 30px;
    height: 100%;
    height: 100vh; }
    .winner-columns .winner-gif__img-wrapper {
      position: relative;
      width: 100%; }
      .winner-columns .winner-gif__img-wrapper p {
        position: absolute;
        bottom: 0;
        right: 8px;
        margin: 0;
        bottom: -31px;
        font-size: 15px; }
    .winner-columns .winner-gif .winner-column-inner {
      align-items: center; }
      .winner-columns .winner-gif .winner-column-inner h3 {
        color: #028223;
        font-size: 40px;
        margin: 68px 0 20px;
        text-shadow: 2px 2px 0px white; }
    .winner-columns .winner-gif .winner-footer {
      opacity: 0;
      animation: fadeIn 0.5s linear 4s;
      animation-fill-mode: forwards; }
    .winner-columns .winner-gif img {
      width: 100%;
      box-shadow: 3px 4px 8px 8px rgba(255, 255, 255, 0.2); }

.site-loader-outer {
  position: fixed;
  height: 100%;
  width: 100%;
  background: red;
  z-index: 100; }
  .site-loader-outer img {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0; }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.all-done__jab {
  opacity: 0;
  transform: translateY(15px);
  color: #E3D100;
  opacity: 0;
  animation: fadeInUp 0.3s linear 2s;
  animation-fill-mode: forwards;
  font-size: 28px;
  line-height: 33px;
  animation-fill-mode: forwards; }

.all-done__footer {
  opacity: 0;
  animation: fadeIn 0.7s linear 5s;
  animation-fill-mode: forwards;
  margin-top: 30px; }

.all-done__credit {
  opacity: 0;
  animation: fadeIn 0.9s linear 8.5s;
  animation-fill-mode: forwards;
  margin-top: 30px; }

.all-done p a:link, .all-done p a:visited {
  color: #E3D100; }

.all-done p a:hover, .all-done p a:active {
  color: #028223; }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

/* Firefox */
input[type=number] {
  -moz-appearance: textfield; }

@media (max-width: 767px) {
  .winner-columns {
    flex-direction: column; }
    .winner-columns .winner-left {
      width: 100%; }
    .winner-columns .winner-gif {
      width: 100%; }
  .winner-columns .winner-left {
    width: 100%;
    min-height: auto;
    margin: 30px 0; }
    .winner-columns .winner-left h1 {
      font-size: 50px !important; }
  .winner-columns .winner-column-inner {
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    min-height: initial;
    display: flex; }
  .winner-columns .winner-gif {
    width: 100%;
    min-height: 30px;
    height: auto;
    height: inital; }
    .winner-columns .winner-gif .winner-column-inner h3 {
      color: green;
      font-size: 40px;
      margin: 20px 0;
      text-shadow: 2px 2px 2px white; }
    .winner-columns .winner-gif .winner-footer {
      opacity: 0;
      animation: fadeIn 0.5s linear 4s;
      animation-fill-mode: forwards;
      margin-top: 53px; }
    .winner-columns .winner-gif img {
      width: 100%; } }

.header--mobile {
  display: none; }

.header {
  position: fixed;
  width: 100%;
  z-index: 500;
  top: 0;
  left: 0;
  right: 0;
  bottom: auto;
  background-color: #FFFFFF;
  /* ELEMENTS */
  /* ELEMENTS */
  /* MODIFIERS */ }
  .header--main {
    height: 130px; }
  .header__column {
    height: 100%; }
  .header__inner, .header__navigation {
    height: 130px; }
  .header__container {
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .header__logo {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center; }
  .header__logo-img {
    max-width: 50px;
    transition: height 0.3s ease; }
  .header__logo-img--mobile {
    margin-left: 23px; }
  .header--mobile {
    height: 65px; }
    .header--mobile .header__inner {
      height: 65px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 20px;
      background-color: #FFFFFF;
      position: relative;
      z-index: 75; }
  .header--collapse {
    transition: height 0.5s; }
    .header--collapse .header__inner,
    .header--collapse .header__navigation {
      transition: height 0.5s; }
  .header--collapsed {
    height: 75px;
    transition: height 0.5s; }
    .header--collapsed .header__inner,
    .header--collapsed .header__navigation {
      height: 75px;
      transition: height 0.5s; }

@media (max-width: 920px) {
  .header--main {
    display: none; }
  .header--mobile {
    display: block; } }

.navigation-menu-outer {
  height: 100%; }

.navigation__menu {
  margin: 0;
  height: 100%;
  display: flex; }
  .navigation__menu:first-child {
    padding-left: 0; }
  .navigation__menu:last-child {
    padding-right: 0; }

.navigation__item {
  list-style-type: none;
  display: inline-block;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0; }

.navigation__link:link, .navigation__link:visited {
  padding: 2px 22px;
  text-decoration: none;
  transition: all 0.2s ease;
  color: #000000;
  background-color: #FFFFFF;
  height: 100%;
  display: flex;
  align-items: center; }

.navigation__link.desktop-screen:hover, .navigation__link.desktop-screen:active {
  color: #1D2EFF; }

.navigation__link.touch-screen:active {
  color: #1D2EFF; }

.navigation--desktop .navigation__submenu {
  float: left;
  left: 0;
  position: absolute;
  top: auto;
  bottom: 0;
  transform: translateY(100%);
  z-index: 1;
  width: 180px; }
  .navigation--desktop .navigation__submenu .navigation__item {
    overflow: hidden;
    transition: all 0.3s ease;
    width: 100%;
    height: auto;
    max-height: 0;
    justify-content: flex-start;
    width: 100%; }
  .navigation--desktop .navigation__submenu .navigation__link {
    padding: 15px 22px;
    display: block;
    width: 100%; }

.navigation--desktop .navigation__item.menu-item-has-children:hover .navigation__item {
  max-height: 100px; }

.navigation--social .navigation__item {
  padding: 2px 10px; }

.hero {
  text-align: center;
  padding: 50px 0; }
  .hero p {
    max-width: 600px;
    margin: 0 auto 20px auto;
    font-size: 22px;
    text-align: center;
    text-shadow: 1px 1px 1px #028223; }
    .hero p:last-child {
      margin-bottom: 50px; }
  .hero ol li {
    text-align: center;
    width: 100%;
    position: relative;
    line-height: 25px; }
  .hero--all-done p:last-child {
    margin-bottom: 0; }

@media (max-width: 767px) {
  .hero {
    text-align: center;
    padding: 50px 0; }
    .hero p {
      font-size: 17px;
      text-align: center;
      line-height: 16px; } }

.navigation--mobile {
  z-index: 50;
  height: auto;
  width: 100%;
  top: 65px;
  transform: translateY(-100%);
  justify-content: flex-start;
  transition: transform 0.3s;
  position: absolute;
  background-color: #FFFFFF; }
  .navigation--mobile .navigation__item {
    align-items: flex-end;
    flex-direction: column; }
  .navigation--mobile .navigation__link {
    display: block;
    text-align: right;
    width: 100%;
    padding: 15px 20px; }
  .navigation--mobile .navigation__submenu {
    display: block;
    padding-right: 30px; }
    .navigation--mobile .navigation__submenu .navigation__item {
      display: block; }

.header--mobile-menu-active .hamburger__line {
  background: #000000; }
  .header--mobile-menu-active .hamburger__line:nth-child(2) {
    opacity: 0; }
  .header--mobile-menu-active .hamburger__line:first-child {
    top: 6px;
    transform: rotate(-45deg); }
  .header--mobile-menu-active .hamburger__line:last-child {
    top: 6px;
    transform: rotate(45deg); }

.header--mobile-menu-active .navigation--mobile {
  transform: translateY(0); }

.hamburger {
  position: relative;
  height: 65px; }
  .hamburger__inner {
    cursor: pointer;
    position: absolute;
    right: 0;
    display: block;
    height: 35px;
    width: 40px;
    top: 16px; }
  .hamburger__line {
    width: 22px;
    height: 2px;
    background: #000000;
    position: absolute;
    top: 6px;
    left: 9px;
    margin-top: 10px;
    transition: all 0.3s; }
    .hamburger__line:first-child {
      top: 0; }
    .hamburger__line:last-child {
      top: 12px; }

.btn::before, .btn::after {
  position: absolute;
  content: '';
  transition: all .5s; }

.btn {
  border: solid 2px;
  padding: 2px 8px;
  text-decoration: none;
  outline: none;
  font-family: inherit;
  display: inline-block;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
  padding: 18px 33px 19px 33px;
  padding-right: 33px;
  position: relative;
  cursor: pointer;
  border: none;
  display: inline-block;
  color: white;
  vertical-align: middle;
  text-decoration: none;
  transition: all .5s;
  color: #fff;
  cursor: url("../assets/img/tree.png"), auto;
  text-shadow: 3px 3px 0px red;
  font-size: 27px;
  position: relative;
  margin: 13px;
  -webkit-text-stroke-width: 3px;
  -webkit-text-stroke-color: green;
  background: transparent linear-gradient(172deg, #FF0000 0%, #FF00D8 100%) 0% 0% no-repeat padding-box;
  border: solid 5px green;
  border-radius: 0; }
  .btn::before {
    bottom: -10px;
    height: 10px;
    width: 100%;
    left: 8px;
    transform: skewX(45deg);
    background-color: #028223; }
  .btn::after {
    right: -8px;
    height: 100%;
    width: 10px;
    bottom: -8px;
    transform: skewY(45deg);
    background-color: #028223; }
  .btn:link, .btn:visited {
    color: inherit;
    text-decoration: none; }
  .btn:hover, .btn:active {
    animation: buttonSwell 0.5s linear;
    animation-iteration-count: infinite;
    cursor: url("../assets/img/tree.png"), auto; }
  .btn.mobile-screen:hover, .btn.mobile-screen:active {
    -webkit-tap-highlight-color: transparent;
    text-decoration: none;
    color: inherit; }
  .btn.desktop-screen:hover, .btn.desktop-screen:active {
    background-color: #000000;
    border-color: #000000;
    color: #FFFFFF; }
  .btn__chevron {
    position: absolute;
    top: calc(50% - 8px); }
  .btn--right {
    padding-right: 51px; }
    .btn--right .button__chevron {
      right: 26px; }
    .btn--right .button__chevron--right {
      right: 20px; }
  .btn--big {
    font-size: 50px;
    line-height: 60px;
    padding: 10px 50px 19px;
    border: solid 10px green;
    cursor: url("../assets/img/tree.png"), auto; }
    .btn--big:link, .btn--big:visited {
      color: #fff; }
    .btn--big:hover, .btn--big:active {
      transform: scale(1.02);
      cursor: url("../assets/img/tree.png"), auto; }
    .btn--big.play-again {
      font-size: 90px; }
    .btn--big::before {
      bottom: -28px;
      height: 18px;
      width: calc(100% + 19px);
      left: 0;
      -webkit-transform: skewX(45deg);
      transform: skewX(45deg);
      background-color: #E3D100; }
    .btn--big::after {
      right: -28px;
      height: calc(100% + 21px);
      width: 18px;
      top: -2px;
      -webkit-transform: skewY(45deg);
      transform: skewY(45deg);
      background-color: #CEBE03; }
    .btn--big:active {
      margin-left: 10px;
      margin-top: 10px; }
      .btn--big:active::before {
        bottom: -5px;
        height: 5px;
        left: 3px; }
      .btn--big:active::after {
        right: -5px;
        width: 5px;
        bottom: -3px; }
    .btn--big.get-started, .btn--big.enter-names, .btn--big.submit-form {
      font-size: 30px; }
  .btn-success {
    font-size: 30px;
    line-height: 40px;
    padding: 10px 50px 13px;
    border: solid 10px green; }
    .btn-success::before {
      bottom: -28px;
      height: 18px;
      width: calc(100% + 19px);
      left: 0;
      -webkit-transform: skewX(45deg);
      transform: skewX(45deg);
      background-color: #E3D100; }
    .btn-success::after {
      right: -28px;
      height: calc(100% + 21px);
      width: 18px;
      top: -2px;
      -webkit-transform: skewY(45deg);
      transform: skewY(45deg);
      background-color: #CEBE03; }
    @media (max-width: 767px) {
      .btn-success {
        padding-left: 20px;
        padding-right: 20px; } }
  .btn--left {
    padding-left: 51px; }
    .btn--left .button__chevron {
      left: 26px; }
    .btn--left .button__chevron--left {
      left: 20px; }
  .btn--white {
    color: #fff; }
  .btn--chevron:hover .chevron:before, .btn--chevron:hover .chevron:after {
    background-color: #FFFFFF; }

.chevron {
  position: absolute;
  transition: all 0.3s; }
  .chevron--left {
    top: 10px;
    left: 1px;
    width: 25px;
    height: 25px; }
    .chevron--left:before, .chevron--left:after {
      content: "";
      width: 16px;
      height: 2px;
      top: -4px;
      left: 11px;
      background: #000000;
      position: absolute;
      margin-top: 10px;
      transform: rotate(45deg);
      transition: all 0.3s; }
    .chevron--left:after {
      top: 6px;
      transform: rotate(-45deg); }
    .chevron--left:before {
      transform: rotate(135deg); }
    .chevron--left:after {
      transform: rotate(-135deg); }
  .chevron--right {
    top: 10px;
    left: 5px;
    width: 25px;
    height: 25px; }
    .chevron--right:before, .chevron--right:after {
      content: "";
      width: 16px;
      height: 2px;
      top: -4px;
      left: 11px;
      background: #000000;
      position: absolute;
      margin-top: 10px;
      transform: rotate(45deg);
      transition: all 0.3s; }
    .chevron--right:after {
      top: 6px;
      transform: rotate(-45deg); }

@media (max-width: 767px) {
  .button {
    font-size: 12px;
    padding: 13px 32px 15px 25px; }
    .button--right {
      padding-right: 38px; }
      .button--right .button__chevron {
        right: 18px;
        top: calc(50% - 7px); }
    .button--left {
      padding-left: 38px; }
      .button--left .button__chevron {
        left: 18px;
        top: calc(50% - 7px); }
  .btn-success {
    font-size: 21px; }
  .btn--big,
  .btn-success {
    font-size: 21px;
    -webkit-text-stroke-width: 2px;
    line-height: 25px; } }

/* form */
.gform_body {
  position: relative;
  z-index: 10; }

.gform_footer {
  clear: both;
  position: relative;
  z-index: 5;
  text-align: right;
  margin-top: 35px; }

.validation_error {
  color: #FC101D;
  position: absolute; }

img.gform_ajax_spinner {
  display: none !important; }

input[type="text"], input[type="email"], input[type="number"], textarea, .chosen-container-single .chosen-single, .chosen-container .chosen-results li {
  border-radius: 0;
  border: solid thin #212F3D;
  color: #000000;
  display: inline-block;
  font-family: inherit;
  font-size: 30px;
  padding: 0 30px;
  letter-spacing: normal;
  resize: none;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  text-align: left;
  width: 100%;
  cursor: url("../assets/img/tree.png"), auto; }
  input[type="text"]:hover, input[type="text"]:active, input[type="email"]:hover, input[type="email"]:active, input[type="number"]:hover, input[type="number"]:active, textarea:hover, textarea:active, .chosen-container-single .chosen-single:hover, .chosen-container-single .chosen-single:active, .chosen-container .chosen-results li:hover, .chosen-container .chosen-results li:active {
    cursor: url("../assets/img/tree.png"), auto; }
  input[type="text"]:focus, input[type="email"]:focus, input[type="number"]:focus, textarea:focus, .chosen-container-single .chosen-single:focus, .chosen-container .chosen-results li:focus {
    outline: none;
    border: solid 3px #028223;
    box-shadow: 7px 7px 0px green;
    color: #000000; }

.cta-row {
  text-align: center;
  width: 100%; }
  @media (max-width: 767px) {
    .cta-row {
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 30px; } }

.cta-legal {
  margin: 20px 0 20px; }
  .cta-legal p {
    font-style: italic; }
    .cta-legal p a:link, .cta-legal p a:visited {
      color: #E3D100; }
    .cta-legal p a:hover, .cta-legal p a:active {
      color: #028223; }

.form-row {
  width: 100%;
  display: block; }
  .form-row--people-counter {
    text-align: center; }
    .form-row--people-counter input {
      max-width: 300px;
      margin: 0 auto;
      font-size: 50px;
      text-align: center;
      border-radius: 20px;
      margin-bottom: 12px; }

label {
  display: block;
  margin-bottom: .5rem;
  font-size: 40px;
  color: #028223;
  text-shadow: 1px 1px 1px white;
  text-transform: none;
  font-weight: bold; }

.people-form__inputs {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around; }
  .people-form__inputs .form-row {
    width: calc(50% - 10px); }

@media (max-width: 767px) {
  .people-form__inputs .form-row {
    width: 100%; }
  label {
    font-size: 24px; } }

.song-player {
  position: fixed;
  bottom: 10px;
  right: 5px;
  text-align: right;
  transform: scale(0.9);
  opacity: 0.6;
  transition: opacity 0.3s; }
  .song-player h5 {
    text-align: right; }
  .song-player:hover, .song-player:active {
    opacity: 1; }
  .song-player a {
    color: #028223; }
    .song-player a:hover, .song-player a:active {
      text-decoration: underline;
      cursor: pointer; }

@keyframes spin {
  0% {
    transform: rotate(-45deg); }
  50% {
    transform: rotate(-121deg); }
  100% {
    transform: rotate(-45deg); } }

@media (max-width: 767px) {
  .starburst-outer {
    height: 127px;
    width: 100%;
    position: absolute;
    overflow: hidden; } }

.starburst-wrapper {
  position: absolute;
  right: 0;
  top: 0;
  transform: rotate(21deg);
  position: fixed;
  right: 80px;
  top: 0; }
  @media (max-width: 767px) {
    .starburst-wrapper {
      position: absolute;
      right: 34px;
      top: -27px;
      transform: rotate(21deg) scale(0.8); } }

.starburst {
  position: relative;
  /* Context */
  top: 2.5em;
  left: 2.5em;
  transform: rotate(-45deg); }
  .starburst:hover {
    animation: spin 1s;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards; }
  .starburst, .starburst:before, .starburst:after, .starburst-body, .starburst-body:before, .starburst-body:after {
    height: 6.5em;
    width: 6.5em;
    background: #E3D100;
    box-sizing: initial; }
  .starburst:before, .starburst:after, .starburst-body:before, .starburst-body:after {
    content: "";
    position: absolute; }
  .starburst:before, .starburst:after {
    top: 0;
    left: 0; }
  .starburst:before {
    transform: rotate(-30deg); }
  .starburst:after {
    transform: rotate(-15deg); }
  .starburst-body {
    padding: 1.5em 0em;
    height: 3.5em;
    /* height minus padding */
    position: absolute;
    bottom: 0;
    right: 0;
    transform: rotate(45deg);
    z-index: 1;
    /* important so the text shows up */ }
    .starburst-body:before {
      top: 0;
      left: 0;
      transform: rotate(60deg); }
    .starburst-body:after {
      top: 0;
      left: 0;
      transform: rotate(75deg); }
    .starburst-body span {
      position: relative;
      z-index: 100;
      display: block;
      text-align: center;
      color: rgba(255, 255, 255, 0.6);
      font: 0.71429em Sans-Serif;
      text-transform: uppercase; }
      .starburst-body span.starburst-main {
        font-weight: bold;
        font-size: 1.05em;
        line-height: 1;
        color: #fff; }

/* ----------------------------------------------------------------------------
 * Pages
 * ------------------------------------------------------------------------- */
canvas {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0; }

body, html {
  cursor: url("../assets/img/tree.png"), auto; }

.home-wrapper {
  text-align: center;
  text-align: center; }
  .home-wrapper--starting .home-panel__form-outer {
    display: block; }
  .home-wrapper--starting .home-panel__loader-content {
    transform: scale(0);
    transition: all 0.3s ease; }
  .home-wrapper--starting .home-panel__form-outer {
    transform: scale(1);
    transition: all 0.3s ease 0.5s; }

h1 {
  margin-bottom: 120px;
  line-height: 70px;
  text-align: center;
  margin: 20px auto 40px; }

p {
  text-align: center;
  color: #fff; }

.home-panel {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  overflow: scroll; }
  .home-panel__label {
    display: block;
    color: #fff;
    font-size: 40px;
    margin: 0 0 21px 0; }
  .home-panel__input {
    font-size: 80px;
    padding: 0px;
    font-family: inherit;
    width: 160px;
    text-align: center; }
  .home-panel .name-fields {
    display: flex;
    flex-wrap: wrap; }
  .home-panel__santa-outer {
    position: relative;
    z-index: 10;
    padding-top: 200px; }
  .home-panel__results {
    display: flex;
    height: 50vh;
    width: 90%;
    align-items: center; }
    .home-panel__results .half {
      width: 50%;
      flex-basis: 50%; }
  .home-panel__inner {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-content: center;
    flex-direction: column; }
  .home-panel__form-outer {
    transform: scale(0);
    display: none; }
    .home-panel__form-outer--step-two .home-panel__form-inner {
      transform: translateX(-50%);
      transition: all 0.3s ease-in-out; }
  .home-panel__form {
    position: relative;
    height: 100vh;
    overflow: hidden; }
  .home-panel__form-inner {
    display: flex;
    position: absolute;
    height: 100%;
    width: 200%; }
  .home-panel__form-step {
    width: 100%;
    flex-basis: 100%; }
  .home-panel--intro .home-panel__sant-outer {
    height: 100%;
    margin-top: 200px; }
  .home-panel--loader {
    background: red;
    background-repeat: repeat;
    background-size: 100px; }
    .home-panel--loader.active {
      z-index: 100; }
  .home-panel--intro {
    background-color: red;
    z-index: 50; }
    .home-panel--intro img {
      height: 90%; }
  .home-panel--start {
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 10; }
  .home-panel--thinking {
    display: none;
    z-index: 20;
    background: repeating-radial-gradient(circle at top left, cyan, yellow 2em, magenta 4em, cyan 6em), repeating-radial-gradient(circle at bottom left, cyan, yellow 2em, magenta 4em, cyan 6em), repeating-radial-gradient(circle at top right, cyan, yellow 2em, magenta 4em, cyan 6em), repeating-radial-gradient(circle at bottom right, cyan, yellow 2em, magenta 4em, cyan 6em);
    background-blend-mode: multiply;
    /*background-color: */ }
    .home-panel--thinking img {
      height: 90%; }
  .home-panel--results {
    top: 100%;
    bottom: 0;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 30; }
    .home-panel--results.active {
      top: 0;
      transition: all 0.1s ease-in-out; }

.person {
  font-size: 100px;
  line-height: 100px;
  margin-bottom: 80px;
  margin: 0;
  background: -webkit-linear-gradient(white, green);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 13px 10px 0px red; }

.person-gif {
  height: 300px; }

.play-again {
  opacity: 0;
  transition: all 0.3s ease-in-out;
  margin-bottom: 120px; }
