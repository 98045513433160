html, body {
	@include headingFont;
}

h1, h2, h3, h4, h5, h6, p, ul, li, ol {
	
}
a {
	transition: all 0.3s;
	font-size: inherit;
	&:link, &:visited, &:active, &:focus {
		outline: 0;
		cursor: url("../assets/img/tree.png"), auto;
	}
	&:hover {
		text-decoration: none;
	}
}
button {
	&:hover, &:active {
		cursor: url("../assets/img/tree.png"), auto;
	}
}
p, li {
	@include p;
	&.jab {
		color: gold;
		font-size: 28px;
		font-style: italic;
	}
}
a {
	display: inline;
}
.h1-wrapper {
	position: relative;
}
h1 {
	@include h1;
}
h2 {
	@include h2;
}
h3 {
	@include h3;
}
h4 {
	@include h4;
}
h5 {
	@include h5;
}
span {
	@include span;
}
a {
	&:link,
	&:visited {
		color: inherit;
		text-decoration: none;
	}
	&.desktop-screen {
		&:hover,
		&:active {
			-webkit-tap-highlight-color: transparent;
			text-decoration: none;
		}
	}
	&.image-link {
		border-bottom: none;
	}
}

.custom-counter {
	counter-reset: section;
}


ul:not(.bjzm-gallery__list, .bjzm-slideshow__list),
ol {
	margin-left: 10px;
	margin-bottom: 40px;
}
li {
	list-style-type: none;
	margin-bottom: 20px;
	&::before {
		content:"·";
		font-size: 28px;
		left: -10px;
		position: relative;
	}
}



ol {list-style: none; counter-reset: li;     max-width: 700px;
	margin: 0 auto;    max-width: 700px;
	margin: 0 auto 50px auto;}
	ol li::before {content: counter(li); color: $gold;
		display: inline-block; width: 1em;
		margin-left: -1em}

		li {counter-increment: li}  