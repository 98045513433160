.hero {
	text-align: center;
	padding: 50px 0;
	h1 {

	}
	h2 {

	}
	p {
		max-width: 600px;
		margin: 0 auto 20px auto;
		font-size: 22px;
		text-align: center;
		text-shadow: 1px 1px 1px $green;
		&:last-child {
			margin-bottom: 50px;
		}
		small {

		}
	}
	ol li {
		text-align: center;
		width: 100%;
		position: relative;
		line-height: 25px;
	}

	&__content {

	}
	&--all-done {
		p:last-child {
			margin-bottom: 0;
		}
	}
}

@include smallTabletMax {
	.hero {
		text-align: center;
		padding: 50px 0;
		h1 {

		}
		h2 {

		}
		p {
			font-size: 17px;
			text-align: center;
			line-height: 16px;
		}
	}
}