html,
body {
	margin: 0;
	padding: 0;
	height: 100%;
	width: 100%;
	background-color: $red;
	color: $white;
}

.navbar {
	position: absolute !important;
	width: 100%;
	background-color: transparent;
	display: none;
}

#root {
	height: 100%;
}

.page-wrapper {
	min-height: 100%;
	.container {
		height: 100%;
	}
	&__inner {
		display: flex;
		align-items: center;
		min-height: 100%;
		min-height: 100vh;
	}
}

.credit {
	a {
		&:link, &:visited {
			color: $gold;
		}
		&:hover, &:active {
			color: $green;
		}
		
		
	}
}
.main--welcome {
	padding-top: 90px;
	.site-loader-outer {
		animation: fadeOut 1s;
		animation-fill-mode: forwards;
		animation-delay: 6s;
		z-index: 5;
		.credit {
			position: absolute;
			font-size: 13px;
			bottom: 0;
			width: 100%;
			color: red;
			bottom: 90px;
			text-align: right;
			right: 80px;
			a {
				&:link, &:visited {
					color: $gold;
				}
				&:hover, &:active {
					color: $green;
				}
				
				
			}
		}
	}
	.site-main-intro {
		opacity: 0;
		position: relative;
		z-index: 10;
		animation: fadeIn 1s;
		animation-fill-mode: forwards;
		animation-delay: 6s;
	}

	@include smallTabletMax {
	}
}

@include smallTabletMax {
	.main--instructions,
	.main--welcome,
	.main--setup {
		padding-top: 0;
		min-height: 100vh;
		.hero {
			padding-top: 150px;
		}
	}
	.main--setup {
		.hero {
			padding-top: 80px;
		}
	}
	.main--winner {
		min-height: 100vh;
	}
}


/**
* Setup section
*/
.setup-section {
	padding: 20px 0;
	min-height: calc(100vh);
	display: flex;
	flex-direction: column;
	justify-content: center;
	&--counter {
		min-height: initial;
	}
}

.main {
	padding-bottom: 30px;
	width: 100%;
	&--setup {
		background-image: url("../assets/img/santa-one.jpeg");
		background-size: 400%;
		background-position: center;
		background-attachment: fixed;
		padding: 60px 0;
		min-height: 100vh;
	}
	&--welcome {
		
	}
	&--is-winner-loading {
		background: repeating-radial-gradient(circle at top left, cyan, yellow 2em, magenta 4em, cyan 6em), repeating-radial-gradient(circle at bottom left, cyan, yellow 2em, magenta 4em, cyan 6em), repeating-radial-gradient(circle at top right, cyan, yellow 2em, magenta 4em, cyan 6em), repeating-radial-gradient(circle at bottom right, cyan, yellow 2em, magenta 4em, cyan 6em);
		background-blend-mode: multiply;
		height: 100vh;
		h2 {
			margin-top: 20px;
		}
	}
	&--is-winner-announced {
		background-image:
		repeating-linear-gradient(
			90deg,
			firebrick,
			firebrick 20px,
			beige 0,
			beige 25px,
			forestgreen 0,
			forestgreen 35px,
			gold 0,
			gold 40px,
			crimson 0,
			crimson 60px
			),
		repeating-linear-gradient(
			firebrick,
			firebrick 20px,
			beige 0,
			beige 25px,
			forestgreen 0,
			forestgreen 35px,
			gold 0,
			gold 40px,
			crimson 0,
			crimson 60px
			);
		background-blend-mode: multiply;
	}
}

input[type="text"],
input[type="number"] {
	margin: 10px 0;
}



.get-ready {
	img {
		width: auto;
		height: calc(100vh - 230px);
		margin: 20px auto;
		@include smallTabletMax {
			height: calc(72vh - 230px);
		}
	}
}

.get-ready,
.winner-content {
	text-align: center;
	width: 100%;
}

.winner-content {}




@keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@keyframes fadeInUp {
	0% {
		opacity: 0;
		transform: translateY(15px);
	}

	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

/**
* Review page
*/
.setup-section--review {
	p {
		opacity: 0;
		transform: translateY(15px);
		text-shadow: 1px 1px 1px $red;
		&.players {
			transform: none;
			opacity: 1;
			margin-top: 30px;
		}

		&.bonus {
			opacity: 0;
			animation: fadeInUp 0.3s linear 2s;
			animation-fill-mode: forwards;
		}

		&.music {
			opacity: 0;
			animation: fadeInUp 0.3s linear 3.5s;
			animation-fill-mode: forwards;
		}
		&.jab {
			opacity: 0;
			animation: fadeInUp 0.3s linear 5s;
			animation-fill-mode: forwards;
		}
	}

	label {
		opacity: 0;
		animation: fadeInUp 0.3s linear 5s;
		animation-fill-mode: forwards;
	}

	.cta-row {
		opacity: 0;
		animation: fadeInUp 0.3s linear 6.5s;
		animation-fill-mode: forwards;
	}
}



.winner-loading {
	img {
		height: calc(100vh - 130px);
		margin-top: 20px;
		@include smallTabletMax {
			height: calc(72vh - 230px);
		}
	}
}



.winner-columns {
	display: flex;
	height: 100%;

	.h1-wrapper {
		h1 {
			font-size: 100px;
		}
	}

	.winner-left {
		width: 50%;
		min-height: 30px;
		display: flex;
		min-height: 100vh;

	}

	.winner-column-inner {
		justify-content: center;
		flex-direction: column;
		align-items: flex-start;
		height: 100%;
		min-height: 100vh;
		display: flex;
	}

	.winner-gif {
		width: 50%;
		opacity: 0;
		animation: fadeIn 0.2s linear 1.5s;
		animation-fill-mode: forwards;
		min-height: 30px;
		height: 100%;
		height: 100vh;
		&__img-wrapper {
			position: relative;
			width: 100%;
			p {
				position: absolute;
				bottom: 0;
				right: 8px;
				margin: 0;
				bottom: -31px;
				font-size: 15px;
			}
		}

		.winner-column-inner {
			align-items: center;
			h3 {
				color: $green;
				font-size: 40px;
				margin: 68px 0 20px;
				text-shadow: 2px 2px 0px white;
			}
		}

		.winner-footer {
			opacity: 0;
			animation: fadeIn 0.5s linear 4s;
			animation-fill-mode: forwards;
		}

		img {
			width: 100%;
			box-shadow: 3px 4px 8px 8px rgba(255, 255, 255, 0.2)
		}
	}
}

.site-loader-outer {
	position: fixed;
	height: 100%;
	width: 100%;
	background: red;
	z-index: 100;
	img {
		position: fixed;
		height: 100%;
		width: 100%;
		top: 0;
	}
}

.all-done-footer {
}



@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes fadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}


.all-done {
	&__jab {
		opacity: 0;
		transform: translateY(15px);
		color: $gold;
		opacity: 0;
		animation: fadeInUp 0.3s linear 2s;
		animation-fill-mode: forwards;
		font-size: 28px;
		line-height: 33px;
		animation-fill-mode: forwards;
	}
	&__footer {
		opacity: 0;
		animation: fadeIn 0.7s linear 5s;
		animation-fill-mode: forwards;
		margin-top: 30px;
	}
	&__credit {
		opacity: 0;
		animation: fadeIn 0.9s linear 8.5s;
		animation-fill-mode: forwards;
		margin-top: 30px;
	}
	p a {
		&:link, &:visited {
			color: $gold;
		}
		&:hover, &:active {
			color: $green;
		}
		
		
	}
}



input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type=number] {
	-moz-appearance: textfield;
}








@include smallTabletMax {
	.get-ready img,
	.winner-loading img {
		// height: auto;
		// min-height: initial;
	}
	.winner-columns {
		flex-direction: column;
		.winner-left {
			width: 100%;
		}
		.winner-gif {
			width: 100%;
		}
	}
	.winner-columns {


		.winner-left {
			width: 100%;
			min-height: auto;
			margin: 30px 0;
			h1 {
				font-size: 50px !important;
			}
		}

		.winner-column-inner {
			justify-content: center;
			flex-direction: column;
			align-items: flex-start;
			height: 100%;
			min-height: initial;
			display: flex;
		}

		.winner-gif {
			width: 100%;
			min-height: 30px;
			height: auto;
			height: inital;

			.winner-column-inner {
				h3 {
					color: green;
					font-size: 40px;
					margin: 20px 0;
					text-shadow: 2px 2px 2px white;
				}
			}

			.winner-footer {
				opacity: 0;
				animation: fadeIn 0.5s linear 4s;
				animation-fill-mode: forwards;
				margin-top: 53px;
			}

			img {
				width: 100%;
				// box-shadow: 3px 4px 15px 8px rgba(255, 255, 255, 0.6705882352941176);
			}
		}
	}


}







