.song-player {
	    position: fixed;
    bottom: 10px;
    right: 5px;
    text-align: right;
    transform: scale(0.9);
    opacity: 0.6;
    transition: opacity 0.3s;
    h5 {
    	text-align: right;
    }
    &:hover, &:active {
    	opacity: 1;
    }
    a {
    	color: $green;
    	&:hover, &:active {
    		text-decoration: underline;
    		cursor: pointer;
    	}
    	
    }
    
}