%test {
	position: absolute;
	content: '';
	transition: all .5s;
}








@mixin button($padding-right: 33px) {
	border: solid 2px;
	padding: 2px 8px;
	text-decoration: none;
	outline: none;
	font-family: inherit;
	display: inline-block;
	font-size: 14px;
	text-transform: uppercase;
	font-weight: bold;
	padding: 18px 33px 19px 33px;
	padding-right: $padding-right;
	position: relative;
	cursor: pointer;
}

@mixin buttonHover() {
	background-color: $black;
	border-color: $black;
	color: $white;
}

.btn {
	@include button(33px);
	border: none;
	display: inline-block;
	color: white;
	vertical-align: middle;
	text-decoration: none;
	transition: all .5s;
	color: #fff;
	cursor: url("../assets/img/tree.png"), auto;
	text-shadow: 3px 3px 0px red;
	font-size: 27px;
	position: relative;
	margin:  13px;
	-webkit-text-stroke-width: 3px;
	-webkit-text-stroke-color: green;
	background: transparent linear-gradient(172deg, #FF0000 0%, #FF00D8 100%) 0% 0% no-repeat padding-box;
	border: solid 5px green;
	border-radius: 0;
	&::before {
		@extend %test;
		bottom: -10px;
		height: 10px;
		width: 100%;
		left: 8px;
		transform: skewX(45deg);
		background-color: #028223;
	}
	&::after {
		@extend %test;
		right: -8px;
		height: 100%;
		width: 10px;
		bottom: -8px;
		transform: skewY(45deg);
		background-color: #028223;
	}
	&:link, &:visited {
		color: inherit;
		text-decoration: none;	
	}
	&:hover, &:active {
		// transform:scale(1.02);
		animation: buttonSwell 0.5s linear;
		  animation-iteration-count: infinite;
		cursor: url("../assets/img/tree.png"), auto;
	}

	&.mobile-screen {
		&:hover, &:active {
			-webkit-tap-highlight-color: transparent;
			text-decoration: none;
			color: inherit;
		}
	}
	&.desktop-screen {
		&:hover, &:active {
			@include buttonHover();
		}	
	}	
	&__chevron {
		position: absolute;
		top: calc(50% - 8px);
	}
	&--right {
		padding-right: 51px;
		.button__chevron {
			right: 26px;
		}
		.button__chevron--right {
			right: 20px;
		}
	}
	&--big {
		font-size: 50px;
		line-height: 60px;
		padding: 10px 50px 19px;
		border: solid 10px green;
		cursor: url("../assets/img/tree.png"), auto;
		&:link, &:visited {
			color: #fff;
		}
		&:hover, &:active {
			transform:scale(1.02);
			cursor: url("../assets/img/tree.png"), auto;
		}
		&.play-again {
			font-size: 90px;
		}
		&::before {
			bottom: -28px;
			height: 18px;
			width: calc(100% + 19px);
			left: 0;
			-webkit-transform: skewX(45deg);
			transform: skewX(45deg);
			background-color: #E3D100;
		}
		&::after {
			right: -28px;
			height: calc(100% + 21px);
			width: 18px;
			top: -2px;
			-webkit-transform: skewY(45deg);
			transform: skewY(45deg);
			background-color: #CEBE03;
		}
		&:active {
			margin-left: 10px;
			margin-top: 10px;
			&::before {
				bottom: -5px;
				height: 5px;
				left: 3px;
			}

			&::after {
				right: -5px;
				width: 5px;
				bottom: -3px;
			}	
		}
		&.get-started,
		&.enter-names,
		&.submit-form {
			font-size: 30px;
		}
		&.play-again {
			// background:
		}

	}
	&-success {
		font-size: 30px;
		line-height: 40px;
		padding: 10px 50px 13px;
		border: solid 10px green;
		&::before {
			bottom: -28px;
			height: 18px;
			width: calc(100% + 19px);
			left: 0;
			-webkit-transform: skewX(45deg);
			transform: skewX(45deg);
			background-color: #E3D100;
		}
		&::after {
			right: -28px;
			height: calc(100% + 21px);
			width: 18px;
			top: -2px;
			-webkit-transform: skewY(45deg);
			transform: skewY(45deg);
			background-color: #CEBE03;
		}
		@include smallTabletMax {
			padding-left: 20px;
			padding-right: 20px;
		}
	}
	&--left {
		padding-left: 51px;
		.button__chevron {
			left: 26px;
		}
		.button__chevron--left {
			left: 20px;
		}
	}
	&--white {
		color: #fff;
	}
	&--chevron {
		&:hover {
			.chevron {
				&:before, &:after {
					background-color: $white;
				}
			}
		}
	}
}

.chevron {
	position: absolute;
	transition: all 0.3s;
	&--left {
		top: 10px;
		left: 1px;
		@include chevron( 'left' , 25px, 25px, 2px, $black);
	}
	&--right {
		top: 10px;
		left: 5px;
		@include chevron( 'right' , 25px, 25px, 2px, $black);
	}

	
}


@include smallTabletMax {
	.button {
		font-size: 12px;
		padding: 13px 32px 15px 25px;
		&--right {
			padding-right: 38px;
			.button__chevron {
				right: 18px;
				top: calc(50% - 7px);
			}
		}
		&--left {
			padding-left: 38px;
			.button__chevron {
				left: 18px;
				top: calc(50% - 7px);
			}
		}
	}
	.btn-success {
		    font-size: 21px;
	}
	.btn--big,
	.btn-success {
		    font-size: 21px;
	-webkit-text-stroke-width: 2px;
	    line-height: 25px;

	}
}






















