/* https://codepen.io/flodar/pen/erism */
@mixin triangle($direction, $sizeH, $sizeV, $color){
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    -moz-transform: scale(.9999);
      
  @if $direction == top {
    border-width: 0 $sizeV $sizeH $sizeV;
    border-color: transparent transparent $color transparent;
  }
  @if $direction == bottom{
    border-width: $sizeV $sizeH 0 $sizeV;
    border-color: $color transparent transparent transparent;
  }
  @if $direction == left{
    border-width: $sizeV $sizeH $sizeV 0;
    border-color: transparent $color transparent transparent;
  }
  @if $direction == right{
    border-width: $sizeV 0 $sizeV $sizeH;
    border-color: transparent transparent transparent $color;
  }
  @if $direction == topright{
    border-width: 0 $sizeH $sizeV 0;
    border-color: transparent $color transparent transparent;
  }
  @if $direction == bottomright{
    border-width: 0 0 $sizeH $sizeV;
    border-color: transparent transparent $color transparent;
  }
  @if $direction == bottomleft{
    border-width: $sizeH 0 0 $sizeV;
    border-color: transparent transparent transparent $color;
  }
  @if $direction == topleft{
    border-width: $sizeH $sizeV 0 0;
    border-color: $color transparent transparent transparent;
  }
}

@mixin verticalcenter() {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
}

@mixin flexVerticalCenter {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

@mixin iconBackground($src, $width: 30px, $height: 30px) {
    width: $width;
    height: $height;
    background-image: url($src);
    display: inline-block;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
      text-align: left;
      overflow: hidden;
      text-indent: -999px;
}

@mixin linkTransitionOpacity( $time: 0.2s, $easing: ease ) {
    transition: opacity $time $easing;
}

@mixin linkTransitionOpacityHover( $opacity: 0.7 ) {
    opacity: $opacity;
}


/*
* @horizontalcenter 
* Horizontally align an element.
*/

@mixin horizontalcenter() {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
}


/*
* @center
* Horizontally and vertically center an element
*/

@mixin center() {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}


/*
* @absolutesize
* Make element position absolute, width 100% and height 100%
*/

@mixin absolutesize() {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}


/* -------------------------------------------------------------------------
*
* 2- Background Image
*
* - Background Image: position center and size cover
* - Background Image Contain: position center and size contain
* - Background Image Custom: use this only if you want custom settings 
* 
* ------------------------------------------------------------------------- */


/**
* @bgimage
* Given a url, put in a bg image with position center and size cover
*/

@mixin bgimage($url) {
    background-image: url($url);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}


/**
* @bgimagecontain
* Given a url, put in a bg image with position center and size contain
*/

@mixin bgimagecontain($url) {
    background-image: url($url);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}


/**
* @bgimagecustom
*/

@mixin bgimagecustom($url) {
    background-image: url($url);
}


/* -------------------------------------------------------------------------
*
* 3- Floats and clearing
*
* - Clear: Apply this to parent to clear floats.
*
* ------------------------------------------------------------------------- */


/*
* Mixin for clearfix
* @include clearfix;
*/

@mixin clearfix {
    &:before,
    &:after {
        content: " ";
        display: table;
    }
    &:after {
        clear: both;
    }
}


/* -------------------------------------------------------------------------
*
* 4- Fonts
*
* - Font-face family usage examples
*      Create a font face rule that applies to 500 weight text and sources EOT, WOFF2, and WOFF.
*      @include font-face(Samplinal, fonts/Samplinal, 500, normal, eot woff2 woff);
*
*      Create a font face rule that applies to bold and italic text.
*      @include font-face("Samplina Neue", fonts/SamplinaNeue, bold, italic);
*
* ------------------------------------------------------------------------- */

@function str-replace($string, $search, $replace: "") {
    $index: str-index($string, $search);
    @if $index {
        @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
    }
    @return $string;
}

// =============================================================================
// Font Face
// =============================================================================
@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {
    $src: null;
    $extmods: ( eot: "?", svg: "#" + str-replace($name, " ", "_"));
    $formats: ( otf: "opentype", ttf: "truetype");
    @each $ext in $exts {
        $extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
        $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
        $src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
    }
    @font-face {
        font-family: quote($name);
        font-style: $style;
        font-weight: $weight;
        src: $src;
    }
}


/* -------------------------------------------------------------------------
*
* 5- Images
*
* 
* ------------------------------------------------------------------------- */
@mixin chevron($direction, $sizeW, $sizeH,  $weight, $color) {
    width: $sizeW;
    height: $sizeH;
    // background-color: red;
    &:before,
    &:after {
        content: "";
        width: 16px;
        height: 2px;
        top: -4px;
        left: 11px;
        background: $black;
        position: absolute;
        margin-top: 10px;
        transform: rotate(45deg);
        transition: all 0.3s;
    }
    &:after {
        top: 6px;
        transform: rotate(-45deg);
    }
    @if( left == $direction ) {
        &:before {
            transform: rotate(135deg);
        }
        &:after {
            transform: rotate(-135deg);

        }
    }
    @if( next == $direction ) {
    }
}





@keyframes buttonSwell {
    0% {
        transform: scale(1);
    }
    50% {
        transform:scale(1.03);
    }
    100% {
        transform:scale(1);
    }
}

































