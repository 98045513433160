/**
 * Colours
 */
$black: #000000;


// linear-gradient(90deg, rgba(2,130,35,1) 0%, rgba(255,255,255,1) 12%, rgba(206,0,19,1) 26%)

$green: #028223;
$white: #FFFFFF;
$red: #ce0013;
$gold: #E3D100;


$blue: #073ebb;
$orange: #fe9b49;
$theme-highlight: #1D2EFF;

$candyCaneBg: 
 linear-gradient(315deg, rgba(2,130,35,1) 0%, rgba(255,255,255,1) 7%, rgba(206,0,19,1) 13%, rgba(255,255,255,1) 23%, rgba(2,130,35,1) 29%, rgba(255,255,255,1) 34%, rgba(206,0,19,1) 42%, rgba(255,255,255,1) 48%, rgba(2,130,35,1) 54%, rgba(255,255,255,1) 59%, rgba(206,0,19,1) 66%, rgba(255,255,255,1) 74%, rgba(2,130,35,1) 78%, rgba(255,255,255,1) 85%, rgba(206,0,19,1) 89%, rgba(255,255,255,1) 95%, rgba(2,130,35,1) 98%);

$red: red;
$pink: pink;

/* header heights */
$header-main-height: 130px;
$collapse-header-height: 75px;
$header-mobile-height: 65px;
$header-mobile-padding: 20px;

/*
* MEDIA QUERIES
 */
$large_desktop_min: "(max-width: 1920px)";

$small_desktop_min: "(min-width: 1321px)";
$small_desktop_max: "(max-width: 1320px)";

$large_tablet_min: "(min-width: 992px)";
$large_tablet_max: "(max-width: 991px)";

$small_tablet_min: "(min-width: 768px)";
$small_tablet_max: "(max-width: 767px)";

$mobile_min: "(min-width: 481px)";
$mobile_max: "(max-width: 480px)";


$mobile_header_breakpoint_min: "(min-width: 921px)";
$mobile_header_breakpoint: "(max-width: 920px)";

/**
 * Container widths
 */
$container_lg_width: 1150px;
$container_md_width: 940px;
$container_sm_width: 700px;

$tablet_gutter: 30px;
$mobile_gutter: 20px;


/**
 * Colours
 */
$black: #000000;
$black-copy: #231f20;
$white: #FFFFFF;
$red: #FC101D;
$theme-highlight: #1D2EFF;
$theme-red: #ff5e52;
$theme-grey: #f5f5f5;





